/* eslint-disable react/prop-types */
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import './FeedbackForm.scss';
import { ThemedTextField } from '../ThemedTextField/ThemedTextField';
import { YFeedback } from './YFeedback';

const FeedbackForm = ({ handleSubmit, article, initValues, getIsValid }) => {
  const formik = useFormik({
    initialValues: initValues || { message: '', name: '', email: '' },
    validationSchema: YFeedback,
  });

  const { values, handleChange, handleBlur, touched, errors, isValid, validateForm } = formik;

  useEffect(() => {
    (() => validateForm())();
  }, []);

  useEffect(() => {
    handleSubmit(values);
    getIsValid(isValid);
  }, [handleSubmit, values, isValid]);

  return (
    <div className="feedback-form">
      <div>Zum Artikel {article}</div>
      <div className="feedback-form__input-container">
        <ThemedTextField
          fullWidth
          id={`message`}
          name={`message`}
          label="Meine Nachricht*"
          variant="standard"
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.message && Boolean(errors.message)}
          helperText={touched.message && errors.message}
        />
        <ThemedTextField
          fullWidth
          id={`name`}
          name={`name`}
          label="Name*"
          variant="standard"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
        <ThemedTextField
          fullWidth
          id={`email`}
          name={`email`}
          label="Meine E-Mail Adresse*"
          variant="standard"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
        />
      </div>
    </div>
  );
};

export default FeedbackForm;
