import React, { useCallback, useMemo } from 'react';
import './CustomRadiobutton.sass';
import Proptypes from 'prop-types';

const CustomRadiobutton = ({ disabled, isEdited, name, error, id, value, onChange }) => {
  const onChangeClick = useCallback(() => {
    if (disabled) return;

    const syntheticEvent = {
      target: {
        name,
        value: !value,
      },
    };
    onChange(syntheticEvent);
  }, [name, value, onChange]);

  const getClassnameForState = useMemo(() => {
    if (disabled) return 'disabled';
    if (isEdited) return 'edited';
    if (error) return 'error';
    return '';
  }, [disabled, isEdited, error]);

  const getClassnameForSelected = useMemo(() => {
    if (value === true) return 'selected';
    return '';
  }, [value]);

  return (
    <div className="custom-radiobutton">
      <div
        className={`custom-radiobutton__input ${getClassnameForState} ${getClassnameForSelected}`}
        id={id}
        onClick={onChangeClick}></div>
    </div>
  );
};

CustomRadiobutton.propTypes = {
  disabled: Proptypes.bool,
  isEdited: Proptypes.bool,
  error: Proptypes.bool,
  name: Proptypes.string.isRequired,
  id: Proptypes.string.isRequired,
  value: Proptypes.bool.isRequired,
  onChange: Proptypes.func.isRequired,
};

CustomRadiobutton.defaultProps = {
  disabled: false,
  isEdited: false,
  error: false,
};

export { CustomRadiobutton };
