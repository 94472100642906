import { ActionButton } from 'components/ActionButton/ActionButton';
import { CustomModal } from 'components/CustomModal';
import { useAuth } from 'context/Auth';
import React from 'react';
import { logoutWithRedirect } from 'utils/AuthUtils';
import { getBUEmail } from 'utils/tenantUtil';

export function SiteBlockedModal() {
  const { isCIAM } = useAuth();

  return (
    <CustomModal
      isOpen={true}
      title={'Eintragung gelöscht'}
      content={
        <div className="home-page-deleted-profile-text">
          <div>
            Ihre Eintragung im Installateurverzeichnis ist gelöscht. Für eine erneute Aktivierung
            Ihrer Eintragung senden Sie bitte eine E-Mail-Nachricht an{' '}
            <a className="link generic-info__link" href={`mailto:${getBUEmail()}`}>
              {getBUEmail()}
            </a>
          </div>
        </div>
      }
      ctaButtons={
        <ActionButton
          inverted
          onClick={() => {
            logoutWithRedirect(isCIAM);
          }}
          title="Zurück zum Login"></ActionButton>
      }></CustomModal>
  );
}
