import React from 'react';
import PropTypes from 'prop-types';
import { STORAGE_KEY } from './localStorageUtil';

export const checkKeyDown = (e) => {
  // hack for multiline text fields to accept newlines
  if (
    e.target.name !== 'changes' &&
    e.target.name !== 'questionInput' &&
    (e.code === 'Enter' || e.key === 'Enter')
  )
    e.preventDefault();
};

export const FFormDebug = ({ values, errors, touched }) => {
  FFormDebug.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
  };

  return (
    (process.env.REACT_APP_STAGE === 'local' ||
      localStorage.getItem(STORAGE_KEY.FORM_DEBUG) === 'true') && (
      <>
        <div>Values:</div>
        <pre>{JSON.stringify(values, null, 2)}</pre>
        <div>Touched:</div>
        <pre>{JSON.stringify(touched, null, 2)}</pre>
        <div>Errors:</div>
        <pre>{JSON.stringify(errors, null, 2)}</pre>
      </>
    )
  );
};

export const UPLOAD_RESTRICTION_ERROR_MESSAGE = {
  TOTAL_FILE_SIZE: 'Die Gesamtgröße der Dateien darf 5 MB nicht überschreiten.',
  TOTAL_COUNT: 'Es dürfen maximal 10 Dateien hochgeladen werden.',
};

export const VALIDATION_CONSTANTS = {
  TOO_LONG: 'Zu lang',
  MANDATORY: 'Pflichtfeld',
  TOO_SHORT: 'Zu kurz',
  INVALID_EMAIL: 'Keine gültige E-Mail',
};

export const EXPERT_EDITING_STATUS = {
  NEW: 'NEW',
  EDIT: 'EDIT',
};
