/* eslint-disable react/prop-types */
import React, { useState, createContext, useMemo, useContext } from 'react';

const NESteps = createContext({});

export const useNESteps = () => {
  return useContext(NESteps);
};

export const NEStepsContext = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);

  const contextValue = useMemo(
    () => ({
      activeStep,
      setActiveStep,
    }),
    [activeStep, setActiveStep]
  );

  return <NESteps.Provider value={contextValue}>{children}</NESteps.Provider>;
};
