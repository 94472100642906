import { MapTo } from '@adobe/aem-react-editable-components';
import React, { useEffect, useState } from 'react';
import EventsCardLong from '../EventsCard/EventsCardLong';
import './HomePageComponent.scss';
import { HomepageCard } from '../../components/HomepageCard/HomepageCard';
import Image from '../../components/Image';
import { getLexikonUrl } from '../../utils/UrlUtil.js';
import { useMutation, useQuery } from '@apollo/client';
import { USER_STATUS_QUERY } from 'Queries/Queries';
import { useCurrentInstaller } from 'hooks/UseCurrentInstaller';
import { SET_SUBSCRIPTION_STATUS_MUTATION } from 'Queries/Mutations';
import { getTenantId, isBUofCurrentTenant } from 'utils/tenantUtil';
import { EIV_STATUS } from 'api/APIUtils';
import { SiteBlockedModal } from './SiteBlockedModal';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { getSitesCards } from './HomePageComponentData';
import { ComponentTestNE } from 'components/ComponentTestNE/ComponentTestNE';

const HomePageComponent = () => {
  const { sites } = getSitesCards(getTenantId());
  const [showLexikon, setShowLexikon] = useState(true);
  const [siteBlocked, setSiteBlocked] = useState(false);
  const { installer, isLoading: isInstallerLoading } = useCurrentInstaller();

  const { data: userStatusData } = useQuery(USER_STATUS_QUERY, { fetchPolicy: 'network-only' });
  const [setSubscriptionStatus] = useMutation(SET_SUBSCRIPTION_STATUS_MUTATION);
  useEffect(() => {
    if (!isInstallerLoading && installer && isBUofCurrentTenant(installer.pflegende_bu)) {
      setSubscriptionStatus({
        variables: { status: installer.zu_infoversand === 'Ja' },
      });
    }
    if (!isInstallerLoading && installer) {
      const deletedStatus = [EIV_STATUS.ARCHIVED, EIV_STATUS.NOT_SET];
      if (
        deletedStatus.includes(installer.status_strom) &&
        deletedStatus.includes(installer.status_gas)
      ) {
        setSiteBlocked(true);
      }
    }
  }, [installer, isInstallerLoading]);

  useEffect(() => {
    const lexikonUrl = getLexikonUrl();

    fetch(lexikonUrl)
      .then((result) => {
        if (!result.ok) {
          setShowLexikon(false);
        }
      })
      .catch(() => {
        setShowLexikon(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);
  if (isInstallerLoading) {
    return <LoadingIndicator />;
  }
  return siteBlocked ? (
    <SiteBlockedModal></SiteBlockedModal>
  ) : (
    <div id="top-element" className=" home-page">
      <Image />
      <div className="home-page-content">
        {/* <ComponentTestNE /> */}
        <div className="home-page-content-stage">
          <div className="mdl-grid home-page-content-sites">
            {sites.map((card, key = card.id) => {
              if (key === 2) {
                const dynCardData = { ...card };
                dynCardData.teaser.elementsList[0].number =
                  userStatusData?.userStatus?.notifications?.articles;
                dynCardData.teaser.elementsList[1].number =
                  userStatusData?.userStatus?.notifications?.events;
                return <HomepageCard key={key} data={dynCardData} />;
              } else if (key === 3) {
                return <HomepageCard key={key} data={card} disabled={!showLexikon} isLexikon />;
              } else {
                return <HomepageCard key={key} data={card} />;
              }
            })}
          </div>
          <EventsCardLong />
        </div>
      </div>
    </div>
  );
};

MapTo('e-fix/components/content/startpage')(HomePageComponent);
