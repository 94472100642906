import React, { useEffect, useMemo, useState } from 'react';
import './NEStepEmailInput.sass';
import { useNESteps } from 'context/NEStepsContext';
import { ACTIVE_STEP } from 'utils/NEUtis';
import { FormActionButtons } from 'components/DebFormComponents/FormActionButtons';
import { InputField } from 'components/DebFormComponents/InputField';
import { useFormik } from 'formik';
import { YNEEmailSchema } from 'components/NEValidation/NEEmailValidation';
import { FFormDebug } from 'utils/FormUtils';
import { getBUEmail, getTenantId, getTenantInfo, TENANT_ID } from 'utils/tenantUtil';
import { getLogoutRedirectUrl } from 'utils/UrlUtil';
import { getEmailStatusByEmail } from 'api/installer/GetEmailStatusByEmail';
import { isNull, isUndefined } from 'lodash';
import { ErrorBanner } from 'components/ErrorBanner/ErrorBanner';

const link1 = {
  [TENANT_ID.AVA]: 'https://www.avacon-netz.de/de/meinauftragsportal.html',
  [TENANT_ID.EDI]: 'https://www.e-dis-netz.de/de/meinauftragsportal.html',
  [TENANT_ID.SHNG]: 'https://www.sh-netz.com/de/meinauftragsportal.html',
  // [TENANT_ID.SHNG.SHNG]: 'https://www.sh-netz.com/de/meinauftragsportal.html',
  // [TENANT_ID.SHNG.NNNG]: 'https://www.sh-netz.com/de/meinauftragsportal.html',
  // [TENANT_ID.SHNG.HANSG]: 'https://www.hansegas.com/de/meinauftragsportal.html',
  // [TENANT_ID.SHNG.EENG]: 'https://www.elbenergie.com/de/meinauftragsportal.html',
  [TENANT_ID.BAG]: 'https://www.bayernwerk-netz.de/de/meinauftragsportal.html',
};

const ERRORS = {
  ACTIVE: (
    <div>
      Sie haben bereits einen Zugang bei uns/einem unserer Partnerunternehmen und Sie sind im
      Installateurverzeichnis mit Ihrer E-Mail-Adresse eingetragen. Loggen Sie sich direkt im
      Auftragsportal ein (
      <a className="link" href={link1[getTenantId()]}>
        Link1
      </a>
      ). Die Verlängerung/Aktualisierung Ihrer Eintragung erfolgt über unser Informationsportal
      e-fix zu beantragen (
      <a className="link" href={getLogoutRedirectUrl()}>
        Link2
      </a>
      ). Zur Wiederherstellung Ihres Passworts nutzen Sie die Passwort-Vergessen-Funktion des{' '}
      <a className="link" href={link1[getTenantId()]}>
        meinAuftragsportal
      </a>
      .
      {/* Sie
      haben bereits einen Zugang bei uns/einem unserer Partnerunternehmen und Sie sind im
      Installateurverzeichnis mit Ihrer E-Mail-Adresse eingetragen. Eine Verlängerung/Aktualisierung
      Ihrer Eintragung ist über unser Informationsportal e-fix zu beantragen. Zur Wiederherstellung
      Ihres Passworts nutzen Sie die Passwort-Vergessen-Funktion{' '}
      <a className="link"href={getLogoutRedirectUrl()}>logout</a>. Für die Anmeldung rund um einen Netzanschluss
      steht Ihnen zur Verfügung . */}
    </div>
  ),
  PENDING: (
    <div>
      Ihre E-Mail ist bei uns im System hinterlegt, die Registrierung wurde nicht abgeschlossen.
      Wenden Sie sich bitte per E-Mail an uns, damit wir Ihnen eine neue Registrierungsmail zusenden
      können. Ihr Account wird verwaltet durch uns / unsere Partnerunternehmen{' '}
      {getTenantInfo(getTenantId())['subDomain']}. Kontakt E-Mail zur Klärung:{' '}
      <a className="link" href={`mailto:${getBUEmail()}`}>
        {getBUEmail()}
      </a>
    </div>
  ),
  DUPLICATE: (
    <div>
      Sie haben bereits einen Zugang bei uns/einem unserer Partnerunternehmen und Sie sind im
      Installateurverzeichnis mit Ihrer E-Mail-Adresse eingetragen. Wenden Sie sich bitte per E-Mail
      an uns, damit wir Ihren Zugang prüfen und Ihre Freischaltung veranlassen können. Kontakt
      E-Mail zur Klärung:{' '}
      <a className="link" href={`mailto:${getBUEmail()}`}>
        ${getBUEmail()}
      </a>
    </div>
  ),
  ERROR: (
    <div>
      Leider besteht aktuell ein Fehler in unserem System. Versuchen Sie es bitte zu einem späteren
      Zeitpunkt erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an uns:{' '}
      <a className="link" href={`mailto:${getBUEmail()}`}>
        ${getBUEmail()}
      </a>
      `
    </div>
  ),
};

const NEStepEmailInput = () => {
  const { activeStep, setActiveStep } = useNESteps();
  const [errorMessage, setErrorMessage] = useState(undefined);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: YNEEmailSchema,
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
  });

  useEffect(() => {
    if (Object.keys(formik.errors).length !== 0) {
      setErrorMessage(undefined);
    }
    const getData = setTimeout(async () => {
      if (formik.values.email && Object.keys(formik.errors).length == 0) {
        getEmailStatusByEmail(formik.values.email)
          .then((response) => {
            switch (response.status) {
              case 200:
                if (response.data.ciam_status === 'Active') {
                  setErrorMessage(ERRORS.ACTIVE);
                } else if (response.data.ciam_status === 'pending') {
                  setErrorMessage(ERRORS.PENDING);
                } else if (isNull(response.data.ciam_status)) {
                  setErrorMessage(ERRORS.PENDING);
                }
                return;
              case 409:
                setErrorMessage(ERRORS.PENDING);
                return;
              default:
                setErrorMessage(ERRORS.ERROR);
                return;
            }
          })
          .catch(({ response }) => {
            if (response) {
              switch (response.status) {
                case 404:
                  setErrorMessage('');
                  return;
                case 200:
                  if (response.data.ciam_status === 'Active') {
                    setErrorMessage(ERRORS.ACTIVE);
                  } else if (response.data.ciam_status === 'pending') {
                    setErrorMessage(ERRORS.PENDING);
                  } else if (response.data.ciam_status === 'null') {
                    setErrorMessage(ERRORS.PENDING);
                  }
                  return;
                case 409:
                  setErrorMessage(ERRORS.PENDING);
                  return;
                default:
                  setErrorMessage(ERRORS.ERROR);
                  return;
              }
            }
          });
      }
    }, 1000);

    return () => clearTimeout(getData);
  }, [formik.values.email, formik.errors.email]);

  if (activeStep !== ACTIVE_STEP.EMAIL_INPUT) return null;
  const hasErrors = useMemo(() => {
    return (!formik.isValid && isUndefined(errorMessage)) || errorMessage != '';
  }, [formik.values.email, formik.errors.email, errorMessage, formik.isValid]);

  const onBack = () => {
    window.history.back();
  };

  const onSubmit = () => {
    setActiveStep(ACTIVE_STEP.BRANCH_SELCTION);
  };

  return (
    <>
      Bitte geben Sie hier Ihre gültige E-Mail-Adresse an, die von uns als Kontakt zu Ihnen genutzt
      wird. Nach einer erfolgreichen Eintragung ins Installateurverzeichnis nutzen Sie Ihre
      E-Mail-Adresse gleichzeitig für die Zugänge zu unseren Systemen. Für Ihre Firma erhalten Sie
      nur einen Zugang
      <div className="ne-step-email-input">
        <InputField
          fullWidth
          infoText={''}
          key={'email'}
          label="E-Mail-Adresse *"
          name="email"
          oldValue={''}
          variant="standard"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email || ''}
        />
      </div>
      <ErrorBanner>{errorMessage}</ErrorBanner>
      <div className="ne-form__action-buttons">
        <FormActionButtons
          hasErrors={hasErrors}
          titleLeft="Zurück"
          titleRight={activeStep === ACTIVE_STEP.FORM_UPLOAD ? 'Eintrag einreichen' : 'Weiter'}
          onClickLeft={onBack}
          onClickRight={onSubmit}
          errorMessage={''}
        />
      </div>
      <FFormDebug values={formik.values} errors={formik.errors}></FFormDebug>
    </>
  );
};

export { NEStepEmailInput };
