import React from 'react';
import PropTypes from 'prop-types';
import './SectionSubtitle.sass';

export function SectionSubtitle({ children }) {
  return <div className="section-subtitle">{children}</div>;
}

SectionSubtitle.propTypes = {
  children: PropTypes.node,
};
