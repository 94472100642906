/* eslint-disable react/prop-types */
import React from 'react';
import '../EventRegisterForm/EventRegisterForm.scss';
import './ParticipantsTable.scss';

const ParticipantsTable = ({ participants }) => {
  if (!participants.length) {
    return (
      <div className="table__collection__absentParticipants">Keine registrierten Teilnehmer</div>
    );
  }

  const preppedParticipants = participants
    .map((participantObject) => {
      const companyName = participantObject.companyName;
      const installerId = participantObject.installerId;
      const participants = participantObject.participants;
      return participants.map((participant) => ({
        contactEmail: participant.eMail,
        firstName: participant.firstName,
        installerId: installerId,
        companyName: companyName,
        lastName: participant.lastName,
        accountEmail: participant.userId,
      }));
    })
    .flat();

  return (
    <div className="table">
      <table className="table__collection">
        <thead className="table__collection__head">
          <tr>
            <th className="table__collection__header">Firma</th>
            <th className="table__collection__header">Name</th>
            <th className="table__collection__header">Installateurnummer</th>
            <th className="table__collection__header">Kontakt Email</th>
            <th className="table__collection__header">E-fix Account Email</th>
          </tr>
        </thead>
        <tbody className="table__collection__row">
          {preppedParticipants.map((item, index) => (
            <tr key={index}>
              <td className="table__collection__cell">{item.companyName}</td>
              <td className="table__collection__cell">
                {item.firstName} {item.lastName}
              </td>
              <td className="table__collection__cell">{item.installerId}</td>
              <td className="table__collection__cell">{item.contactEmail}</td>
              <td className="table__collection__cell">{item.accountEmail}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ParticipantsTable;
