import React from 'react';
import PropTypes from 'prop-types';
import './InputRow.sass';

export function InputRow(props) {
  return (
    <div className={`input-row input-row${props.children.length > 2 ? '__long' : '__short'}`}>
      {props.children.lengt > 1 ? (
        props.children.map((child) => {
          return child;
        })
      ) : (
        <>{props.children}</>
      )}
    </div>
  );
}

InputRow.propTypes = {
  children: PropTypes.node,
};
