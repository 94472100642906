import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Link, Typography, withStyles } from '@material-ui/core';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { CustomTooltip } from 'components/CustomTooltip/CustomTooltip';
import { InfoIcon } from 'components/Icons/InfoIcon';

const CustomLink = withStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(14),
  },
}))(Link);

const NavigationTooltip = (props) => {
  const { to, title, cta, className } = props;

  return (
    <CustomTooltip
      interactive
      className={className}
      title={
        <div>
          {title}
          <Typography>
            <CustomLink href={to}>{cta}</CustomLink>
          </Typography>
        </div>
      }
      placement="top">
      <IconButton style={{ padding: 0, alignItems: 'center', alignContent: 'center' }}>
        <InfoIcon />
      </IconButton>
    </CustomTooltip>
  );
};

NavigationTooltip.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { NavigationTooltip };
